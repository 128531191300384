import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { PopupModal } from "react-calendly";
import Contactbackground from "../../assets/Partnerprogram/Contactbackground.svg";
import "../HomePage/Navbar/NavbarStyle.css";
import { TbCircleLetterXFilled } from "react-icons/tb";
import Footer1 from "../Footer/Footer1";
import toast, { Toaster } from "react-hot-toast";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../apiConnection";
import '../../App.css'




const Appointment = ({ onMenuToggle }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [nationalNumber, setNationalNumber] = useState("");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false); // To track if OTP is sent
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [phone, setPhone] = useState("");

    const otpRefs = useRef([]);
    const otpRefs1 = useRef([]);

    const handlePhoneChange = (value) => {
        // Ensure the value is a string
        const phoneString = value ? String(value) : "";

        // Update phone state
        setPhone(phoneString);

        // Parse the phone number using libphonenumber-js
        const phoneNumber = parsePhoneNumberFromString(phoneString);
        if (phoneNumber) {
            setCountryCode(phoneNumber.countryCallingCode);
            setNationalNumber(phoneNumber.nationalNumber);
        } else {
            setCountryCode("");
            setNationalNumber("");
        }
    };

    const verifyNumber = () => {
        // Ensure phone is a string
        const phoneString = phone ? String(phone) : "";

        // Extract country code and national number
        const phoneNumber = parsePhoneNumberFromString(phoneString);
        if (phoneNumber) {
            setCountryCode(phoneNumber.countryCallingCode);
            setNationalNumber(phoneNumber.nationalNumber);
            console.log("Country Code:", phoneNumber.countryCallingCode);
            console.log("National Number:", phoneNumber.nationalNumber);
        } else {
            console.error("Invalid phone number");
        }
    };

    const handleOtpChange = (e, index) => {
        const { value } = e.target;

        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value.slice(0, 1); // Ensure single character input
            return newOtp;
        });

        // Move focus to the next input box if the current box is filled
        if (value.length === 1 && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].focus();
        }
    };
    const handleOtpChange1 = (e, index) => {
        const { value } = e.target;

        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value.slice(0, 1); // Ensure single character input
            return newOtp;
        });

        // Move focus to the next input box if the current box is filled
        if (value.length === 1 && index < otpRefs1.current.length - 1) {
            otpRefs1.current[index + 1].focus();
        }
    };

    const verifyEmail = async () => {
        if (isOtpSent) {
            // OTP Verification Logic
            const enteredOtp = otp.join("");
            try {
                const response = await fetch(
                    `${BASE_URL}demo//verify-otp-email`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            email: formData.email,
                            otp: enteredOtp,
                        }),
                    }
                );

                const data = await response.json();

                if (response.ok && data.verify) {
                    // OTP verified successfully
                    setIsEmailVerified(true);
                    setIsOtpSent(false); // Hide OTP input
                } else {
                    // OTP verification failed
                    toast.error("Invalid OTP. Please try again.");
                }
            } catch (error) {
                console.error("Error verifying OTP:", error.message);
            }
        } else {
            // Send OTP logic here
            try {
                const response = await fetch(`${BASE_URL}demo/send-otp-email`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: formData.email }),
                });

                const data = await response.json();
                if (data.message === "OTP sent successfully") {
                    setIsOtpSent(true);
                } else {
                    console.error("Error sending OTP:", data.message);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const handleOnChange = (e) => {
        const input = e.target.value;
        setPhoneNumber(input);

        // Parse the phone number using libphonenumber-js
        const parsedPhoneNumber = parsePhoneNumberFromString(input);
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
            const formattedPhoneNumber = `+${parsedPhoneNumber.countryCallingCode} ${parsedPhoneNumber.nationalNumber}`;

            setCountryCode(`+${parsedPhoneNumber.countryCallingCode}`);
            setNationalNumber(parsedPhoneNumber.nationalNumber);

            setFormData((prevData) => ({
                ...prevData,
                phone: formattedPhoneNumber,
            }));

            // console.log('Country Code:', `+${parsedPhoneNumber.countryCallingCode}`);
            // console.log('National Number:', parsedPhoneNumber.nationalNumber);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                phone: input,
            }));
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (typeof onMenuToggle === "function") {
            onMenuToggle(!menuOpen);
        }
    };

    const handleLinkClick = () => {
        //console.log("Clicked"); // Log a message to the console
        setMenuOpen(false); // Close the menu when a link is clicked
        if (typeof onMenuToggle === "function") {
            onMenuToggle(false);
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        country: "",
        jobRole: "",
        industry: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here, e.g., send data to backend
        //console.log(formData);
        // Clear form fields after submission if needed

        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            country: "",
            jobRole: "",
            industry: "",
        });
    };
    const submitform = () => {
        // Prepare the data to be sent to the API
        const demoUserData = {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            country_code: countryCode,
            phone_no: nationalNumber,
        };

        // Call the createDemoUser API
        fetch(`${BASE_URL}demo/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(demoUserData),
        })
            .then((response) =>
                response.json().then((response) => {
                    if (response.msg === "User created successfully") {
                        toast.success("User created successfully");
                    } else if (
                        response.message === "This user is already been used"
                    ) {
                        toast.error("This user is already used");
                    } else {
                        toast.error("Something went wrong");
                    }
                })
            )
            .catch((error) => {
                toast.error("Error creating user: " + error.message);
            });
    };
    return (
        <>
            <div>
                <Toaster />
            </div>
            <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
                <div className="cursor-pointer">
                    <Link to="/">
                        <p className="flex items-center text-2xl ml-10 font-bold text-gray-500">
                            Rasta
                            <div
                                className="rotating-text-container "
                                style={{ color: "#FF6F17" }}
                            >
                                <div className="rotating-text">.360°</div>
                                <div className="rotating-text">.AI</div>
                                <div
                                    className="rotating-text"
                                    style={{ color: "#FF6F17" }}
                                >
                                    .360°
                                    <span className=" text-green-500">
                                        .Mini
                                    </span>
                                </div>
                                <div className="rotating-text">.360</div>
                                <div className="rotating-text">.AI</div>
                                <div className="rotating-text">.Mini</div>
                                <div className="rotating-text">.360</div>
                                <div className="rotating-text">.AI</div>
                                <div className="rotating-text">.Mini</div>
                                <div className="rotating-text">.360</div>
                                <div className="rotating-text">.AI</div>
                                <div className="rotating-text">.Mini</div>
                            </div>
                        </p>
                    </Link>
                </div>
                <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
                    <Link to="/Ourproducts">
                        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
                            Our Products
                        </li>
                    </Link>
                    <Link to="/Demo">
                        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
                            Demo
                        </li>
                    </Link>
                    <Link to="/Contact">
                        <li
                            className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"
                            style={{ color: "#00836C" }}
                        >
                            Contact
                        </li>
                    </Link>
                    <Link to="/Aboutus">
                        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
                            About us
                        </li>
                    </Link>
                    <Link to="/Faq">
                        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
                            FAQ
                        </li>
                    </Link>
                </ul>
                {/* Apply md:hidden to hide on screens md and larger */}
                <button
                    className="md:hidden mobile-menu-button text-2xl mr-5"
                    style={{ color: "grey" }}
                    onClick={toggleMenu}
                >
                    ☰
                </button>
                {menuOpen && (
                    <div
                        className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
                        style={{ backgroundColor: "rgba(8, 117, 103, 0.9)" }}
                    >
                        <button
                            className="absolute top-5 right-5 text-white text-3xl"
                            onClick={toggleMenu}
                        >
                            <TbCircleLetterXFilled />
                        </button>
                        <Link
                            to="/"
                            className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            Home
                        </Link>
                        <Link
                            to="/Ourproducts"
                            className="text-white text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            Our Products
                        </Link>
                        <Link
                            to="/Demo"
                            className="text-white text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            Demo
                        </Link>
                        <Link
                            to="/Contact"
                            className="text-white text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            Contact
                        </Link>
                        <Link
                            to="/Aboutus"
                            className="text-white text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            About us
                        </Link>
                        <Link
                            to="/Faq"
                            className="text-white text-2xl border-b border-white pb-2 px-4 w-full"
                            onClick={handleLinkClick}
                        >
                            FAQ
                        </Link>
                    </div>
                )}
            </div>

            <div className="hidden md:block flex mt-0 md:mt-40 max-w-6xl mx-auto">
                <div className="w-full bg-green-200 overflow-hidden shadow-md flex">
                    <div
                        className="w-1/2 relative bg-cover bg-center text-white flex items-center justify-center p-8"
                        style={{
                            backgroundImage: `url(${Contactbackground})`,
                            borderRadius: "none", // Remove rounded corners
                        }}
                    >
                        <div className="text-center">
                            <h2 className="text-4xl font-bold mb-4">
                                Get in Touch
                            </h2>
                            <p className="text-lg mb-6">
                                Schedule a meeting to learn more about Rasta.ai
                                and discover how our solutions can benefit your
                                road monitoring needs.
                            </p>
                            <button
                                className="bg-green-700 px-6 py-4 rounded-xl text-white text-lg font-semibold"
                                onClick={() => setIsOpen(true)}
                            >
                                Schedule Meeting
                            </button>
                        </div>
                    </div>

                    <div className="w-1/2 p-8">
                        <h2 className="text-2xl font-semibold mb-4 text-center text-black font-dmsans">
                            Contact Us
                        </h2>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <label
                                        htmlFor="firstName"
                                        className="block text-sm font-medium text-gray-700 font-opensans"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
                                        style={{ height: "auto" }} // Allow input height to adjust based on content
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="lastName"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
                                        style={{ height: "auto" }} // Allow input height to adjust based on content
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Work Email
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        {!isEmailVerified ? (
                                            !isOtpSent ? (
                                                <>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={verifyEmail}
                                                        className="ml-2 bg-green-700 px-3 py-1 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-500 focus:outline-none"
                                                    >
                                                        SendOTP
                                                    </button>
                                                </>
                                            ) : (
                                                <div className="flex space-x-2">
                                                    {otp.map((digit, index) => (
                                                        <input
                                                            key={index}
                                                            ref={(el) =>
                                                                (otpRefs.current[
                                                                    index
                                                                ] = el)
                                                            } // Assign refs
                                                            type="text"
                                                            value={digit}
                                                            onChange={(e) =>
                                                                handleOtpChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            maxLength="1"
                                                            className="w-10 h-10 border-gray-300 rounded-md shadow-sm text-center focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3"
                                                        />
                                                    ))}
                                                    <button
                                                        type="button"
                                                        onClick={verifyEmail}
                                                        className="ml-2 bg-green-700 px-3 py-2 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-500 focus:outline-none"
                                                    >
                                                        Verify
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            <div className="flex  space-x-2">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    disabled
                                                    className="flex-grow mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-20 sm:text-sm py-2 px-3 overflow-hidden text-ellipsis"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                />
                                                <span className="flex-shrink-0 text-green-700 text-2xl">
                                                    &#10003;
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Phone Number
                                    </label>
                                    <div className="mt-1 flex items-center">
                                   
                                     <PhoneInput
                                         id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handlePhoneChange}
                                        defaultCountry="IN" 
                                        className="block w-full h-auto  sm:text-sm py-2 px-3" 
            
                                        />
                                         </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Country/Region
                                    </label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
                                        style={{ height: "auto" }} // Allow input height to adjust based on content
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="jobRole"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Job Role
                                    </label>
                                    <input
                                        type="text"
                                        id="jobRole"
                                        name="jobRole"
                                        value={formData.jobRole}
                                        onChange={handleChange}
                                        required
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
                                        style={{ height: "auto" }} // Allow input height to adjust based on content
                                    />
                                </div>
                                <div className="flex justify-center">
                                <button
        type="button"
        onClick={() => {
            if (isEmailVerified) {
                // Call the submit function if email is verified
                submitform();
            } else {
                // Show a toast message prompting the user to verify the email
                toast.error("Please verify your email before submitting.");
            }
        }}
        className={`bg-green-700 px-6 py-3 rounded-md text-white font-semibold focus:outline-none ${
            isEmailVerified ? 'hover:bg-green-600 focus:bg-green-600' : 'opacity-50 cursor-not-allowed'
        }`}
        disabled={!isEmailVerified} // Disable the button if email is not verified
    >
        Submit
    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <PopupModal
                url="https://calendly.com/rahul-aiunika"
                // Pass additional props as needed
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                rootElement={document.getElementById("root")} // Ensure this matches your root element
            />

            <div className="block md:hidden  mt-20 max-w-6xl mx-auto space-y-8 px-8 md:px-0">
                {/* Contact Us Form */}
                <div className="w-full bg-green-200 overflow-hidden shadow-md flex flex-col p-8  ">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-black font-dmsans">
                        Contact Us
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-4">
                            <div>
                                <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700 font-opensans"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
                                    style={{ height: "auto" }}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
                                    style={{ height: "auto" }}
                                />
                            </div>
                            <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Work Email
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        {!isEmailVerified ? (
                                            !isOtpSent ? (
                                                <>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={verifyEmail}
                                                        className="ml-2 bg-green-700 px-3 py-1 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-500 focus:outline-none"
                                                    >
                                                        SendOTP
                                                    </button>
                                                </>
                                            ) : (
                                                <div className="flex space-x-2">
                                                    {otp.map((digit, index) => (
                                                        <input
                                                            key={index}
                                                            ref={(el) =>
                                                                (otpRefs1.current[
                                                                    index
                                                                ] = el)
                                                            } // Assign refs
                                                            type="text"
                                                            value={digit}
                                                            onChange={(e) =>
                                                                handleOtpChange1(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            maxLength="1"
                                                            className="w-10 h-10 border-gray-300 rounded-md shadow-sm text-center focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3"
                                                        />
                                                    ))}
                                                    <button
                                                        type="button"
                                                        onClick={verifyEmail}
                                                        className="ml-2 bg-green-700 px-3 py-2 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-500 focus:outline-none"
                                                    >
                                                        Verify
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            <div className="flex  space-x-2">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    disabled
                                                    className="flex-grow mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-20 sm:text-sm py-2 px-3 overflow-hidden text-ellipsis"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                />
                                                <span className="flex-shrink-0 text-green-700 text-2xl">
                                                    &#10003;
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Phone Number (with Country Code)
                                    </label>
                                    <div className="mt-1 flex items-center">
                                   
        <PhoneInput
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handlePhoneChange}
            defaultCountry="IN" // Default country for dropdown
            className="block w-full h-auto  sm:text-sm py-2 px-3" // Match padding with other inputs
            
        />
    </div>
                                </div>
                            <div>
                                <label
                                    htmlFor="country"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Country/Region
                                </label>
                                <input
                                    type="text"
                                    id="country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
                                    style={{ height: "auto" }}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="jobRole"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Job Role
                                </label>
                                <input
                                    type="text"
                                    id="jobRole"
                                    name="jobRole"
                                    value={formData.jobRole}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
                                    style={{ height: "auto" }}
                                />
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="button"
                                    onClick={submitform}
                                    className="bg-green-700 px-6 py-3 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-600 focus:outline-none"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Get in Touch Section */}
                <div
                    className="w-full relative bg-cover bg-center text-white flex items-center justify-center p-8  md:pb-0"
                    style={{
                        backgroundImage: `url(${Contactbackground})`,
                        borderRadius: "none", // Remove rounded corners
                    }}
                >
                    <div className="text-center">
                        <h2 className="text-xl md:text-4xl font-bold mb-4">
                            Get in Touch
                        </h2>
                        <p className="text-sm md:text-lg mb-6">
                            Schedule a meeting to learn more about Rasta.ai and
                            discover how our solutions can benefit your road
                            monitoring needs.
                        </p>
                        <button
                            className="bg-green-700 px-6 py-4 rounded-xl text-white text-xs md:text-lg font-semibold"
                            onClick={() => setIsOpen(true)}
                        >
                            Schedule Meeting
                        </button>
                    </div>
                </div>

                {/* Calendly PopupModal */}
                <PopupModal
                    url="https://calendly.com/rahul-aiunika"
                    onModalClose={() => setIsOpen(false)}
                    open={isOpen}
                    rootElement={document.getElementById("root")}
                />
            </div>

            <div className="mt-10 md:mt-20 ">
                <Footer1 />
            </div>
        </>
    );
};

export default Appointment;
